import { defineStore } from "pinia"
import { useMessage } from "./message"
import { useUserStore } from "~/stores/user"
import { useSocket } from "~/composables/socket"
import type { StoreTypes } from "~/types/store"
import { SocketAction } from "~/types/store"
import { useChristmasStore } from "~/stores/christmas"

const initialValue: StoreTypes.Socket.State = {
  ws: null,
  data: null,
  status: "NONE",
}

export const useSocketStore = defineStore("socket", {
  state: () => {
    return {
      ...initialValue,
    }
  },
  actions: {
    /**
     * 连接 socket
     */
    async CONNECT_SOCKET() {
      console.log("Starting Websocket Connection")

      try {
        const socket = useSocket(this.ActionHandler)
        const ws = (await socket.initSocket()) as any
        if (!ws || !ws.ws) return
        // console.log(ws)
        this.$patch({
          ws: ws.ws.value,
        })
      } catch (e) {
        console.log(e, 9090)
      }
    },
    /**
     * 断开连接
     */
    CLOSE_SOCKET() {
      console.log("Closing Websocket Connection")

      if (this.ws) {
        this.ws.close()
        this.ws = null
      }
    },
    ActionHandler(data: any) {
      switch (data?.action) {
        // 公告消息通知
        case SocketAction.SYSTEM_NOTIFICATION:
          useMessage().GET_NOTICE() // 获取公告
          break
        //  站内信 通知
        case SocketAction.STATION_LETTER:
          useMessage().GET_NOTICE_COUNT() // 获取消息数量
          break
        // 消息更新
        case SocketAction.NOTICE_CHANGE:
          useMessage().GET_NOTICE_COUNT() // 获取消息数量
          break
        case SocketAction.TRANSFER_MESSAGE:
          useMessage().GET_NOTICE_COUNT() // 获取消息数量
          useUserStore().GET_USER_PROFILE()
          break
        case SocketAction.DEPOSIT_COIN_CHANGE:
          useUserStore().GET_USER_PROFILE() // 获取用户信息
          useUserStore().GET_USER_VIP_INFO() // vip信息
          break
        // 流水变化  更新余额
        case SocketAction.COIN_CHANGE:
          useUserStore().GET_USER_PROFILE() // 获取消息数量
          useChristmasStore().APPLY_COIN(data?.message)
          break
        // 踢出用户
        case SocketAction.KICK_OUT_USER:
          useUserStore().LOGOUT()
          break
        // 圣诞活动助力讯息变更通知
        case SocketAction.HELP_DETAIL_CHANGE:
          useChristmasStore().HELP_DETAIL_CHANGE()
          break
        default:
          break
      }
    },
  },
})
