import { useAppStore } from "~/stores/app"

export const useSocket = (callback: (arg: unknown) => void) => {
  /** get socket url */
  const getSocketUrl = () => {
    const config = useAppStore().config
    const socketUrl =
      config?.find((item) => item.title === "ws_server")?.value ?? ""
    return socketUrl
  }
  /** init socket */
  const initSocket = async () => {
    try {
      const url = await getSocketUrl()
      const token = useToken().getToken()
      if (url && token) {
        const socketUrl = `${url}/${token}/d/0`
        const websocket = useWebSocket(socketUrl, {
          autoReconnect: {
            retries: 10,
            delay: 10000,
            onFailed() {},
          },
          onMessage: (_, event) => {
            try {
              const payload = JSON.parse(event.data)
              callback(payload)
            } catch (e) {
              console.log(e)
            }
          },
          heartbeat: {
            message: JSON.stringify({
              // 连接成功将，用户ID传给服务端
              // token: pushToken,
              // source: "mer",
              action: "KEEP_ALIVE",
              bearer: "",
              device: "",
            }),
            interval: 5000,
            pongTimeout: 10000,
          },
        })
        return websocket
      }
    } catch (e) {}
  }
  return {
    initSocket,
  }
}
