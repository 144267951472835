import type { WebSocketStatus } from "@vueuse/core"
import { GiftBoxStatus } from "~/stores/christmas"

export enum SocketAction {
  PUSH_WN = "PUSH_WN",
  PUSH_DN = "PUSH_DN",
  NOTICE_CHANGE = "NOTICE_CHANGE",
  COIN_CHANGE = "COIN_CHANGE",
  DEPOSIT_COIN_CHANGE = "DEPOSIT_COIN_CHANGE",
  SYSTEM_NOTIFICATION = "SYSTEM_NOTIFICATION",
  STATION_LETTER = "STATION_LETTER",
  TRANSFER_MESSAGE = "TRANSFER_MESSAGE",
  KICK_OUT_USER = "KICK_OUT_USER",
  /** 圣诞活动推送 */
  HELP_DETAIL_CHANGE = "HELP_DETAIL_CHANGE",
}

type BaseListDataType<Data> = {
  current: `${number}`
  pages: `${number}`
  size: `${number}`
  total: `${number}`
  list: Data[]
}

type ToState<__State> = {
  [K in keyof __State]: __State[K] | null
}
/**
 * store 数据返回
 */
export declare namespace StoreTypes {
  type NumberString = `${number}.${number}`
  type GameType = {
    createdAt: number
    createdBy: string
    // 显示标志:0隐藏,1显示
    display: 0 | 1
    id: number
    name: string
    sort: number
    // 状态:0停用,1启用
    status: 0 | 1
    updatedAt: number
    updatedBy: string
  }
  /** APP */
  namespace App {
    type Config = {
      id: number
      title: string
      titleZh: string
      value: string
    }
    type Dictionary = {}
    type PageScrollTop = { [K in RouteName]: number }
    type State = {
      config: Config[]
      dictionary: Dictionary[]
      pageScrollTop: PageScrollTop
      language?: string | null
      kycValidDialogVisible?:any
      registerItemData?:any[]
    }
  }
  namespace OldSite {
    type State = {
      showOldsiteBtn: boolean
    }
  }
  /** card */
  type Card<T> = {
    cardList: T[]
    configList: T[]
    withdrawConfig: T[]
    parseJson: number[]
    rates: any
  }
  /** google */
  namespace Google {
    type GoogleInfo = {
      qrCode?: string
      secret?: string
      providerInfo: {
        areaCode?: string
        facebookUserId?: unknown
        googleCodeStatus?: unknown | number
        googleLoginEmail?: unknown | string
        kycStatus?: IKycForm["status"]
        mobile?: unknown | string
        password?: string
        msg?: string
      }
    }
  }
  /** Game */
  type Game<T = any> = {
    collectionList: T[]
    bannerList: T[]
    slotGameList: SpaceGameRelated.SlotGameList.ResponseData["list"]
    endingList: T[]
    endingInfo: {}
    gameBannerList: {
      createdAt: number
      createdBy: string
      gameGroupId: number
      h5Img: string
      id: number
      name: string
      pcImg: string
      sort: number
      status: number
      updatedAt: number
      updatedBy: string
    }[]
    gameTypeList: GameType[]
  }

  namespace WithdrawDetail {
    type WithdrawDetail = {
      /**
       * 提款單號
       */
      codeRecordsList: {
        /**
         * 1: 充值 2: 簽到活動 3: 系統調漲 4: 註冊活動 5: 充值活動（彩金）
         */
        turnoverType: 1 | 2 | 3 | 4 | 5
        /**
         * 已完成打碼量
         */
        completed: `${number}`
        /**
         * 未完成打碼量
         */
        undone: `${number}`
      }[]
      /**
       * 扣除彩金
       */
      bonus: `${number}`
      /**
       * 真實提款金額
       */
      actualWithdrawal: `${number}`
      /**
       * 稽核手續費
       */
      auditFee: `${number}`
      /**
       * 最小提款金額
       */
      minAmount: `${number}`
      /**
       * 是否達到最小提款金額
       */
      isMinActualWithdrawal: number
      /**
       * 尚未派發的彩金
       */
      unaccalimedBonus: `${number}`
    }
  }

  /** Help */
  namespace Help {
    type State = {
      detail: any | null
      typesList: {
        current: `${number}`
        list: {
          id: number
          imageUrl: string
          listHelpInfo: {
            content: string
            createBy: string
            createdAt: number
            helpTypeId: number
            helpTypeName: string | null
            id: number
            language: string
            sort: number
            status: number
            title: string
            updateBy: string
            updatedAt: number
          }[]
          typeName: string
        }[]
        pages: `${number}`
        size: `${number}`
        total: `${number}`
      } | null
      contentList: {
        list: {
          content: string
          createBy: string
          createdAt: number
          helpTypeId: number
          helpTypeName: string | null
          id: number
          language: string
          sort: number
          status: number
          title: string
          updateBy: string
          updatedAt: number
        }[]
        current: `${number}`
        pages: `${number}`
        size: `${number}`
        total: `${number}`
      } | null
    }
  }
  /** Home */
  type Home<T = any> = {
    bannerList: T[]
    jackpotList: T
    rankList: T[]
    classificationList: SpaceGameRelated.GameClassifyList.ResponseData["list"]
    loginBannerList: T[]
    isVisible: boolean
    searchFocus: boolean
  }
  /** Message */
  type Message<T = any> = {
    noticeCountList: T[]
    noticeList: T[]
  }

  /** User */
  namespace User {
    type Info = {
      address: string
      apiToken: string
      areaCode: string
      avatar: string
      bindBank: number
      birthday: string | null
      coin: `${number}.${number}`
      coinCommission: `${number}.${number}`
      createdAt: number
      email: string
      fcoin: `${number}.${number}`
      googleCodeStatus: number
      id: number
      isPromoter: number
      kycStatus: IKycForm["status"]
      levelId: number
      mobile: string
      passwordCoin: string
      passwordHash: string
      promoCode: string
      realName: string
      role: number
      score: number
      sex: number
      signature: string
      status: number
      upgradeBalance: `${number}.${number}` | null
      username: string
      balance: number
      vipLevelCodeIcon: string
      isHashB: 0 | 1
    }
    type __State = {
      isLogin: boolean
      info: Info
      isPlay: boolean
      vipInfo: any
      vipList: any[]
      withdrawConfig: any
      dialogType: DialogTypeKey
      isNavBarOpne: boolean
      userKyc: IKycForm
      showZeroBalance: boolean
      showFiatBalance: boolean
      kycIsVisibleData?:any[]
    }
    type State = {
      [K in keyof __State]: __State[K] | null
    }
    type providerLogin = {
      authCode: string | unknown
      thirdType: string
      device: string
      promoCode?: string
    }
  }

  type UserWallet = {
    currency: string
    coin: string
    frozen: string
    category: number
    coinUrl: string
    coinGameType: string
  }
  type Coin = {
    coinTypeId: number
    title: string
    chainId: number
    coinType: string
    enable: number
    iconUrl: string
    coinUrl: string
    decimals: number
    sort: number
    coinGameType: string
    minimumAmount: number
    defiMinAmount: number
    discountPercent: number
    coingeckoId: string
    withdrawMinAmount: number
    withdrawFee: number
    withdrawMaxAmount: number
  }
  type CryptoAddress = {
    currency: string
    network: string
    address: string
  }
  /** Wallet */
  type Wallet<T = any> = {
    walletList: {
      allWithdrawalCoin?: string
      bonus: string
      bonusCode?: string
      mainCurrency: string
      needCodeCoin: string
      reelCoin: string
      coinUrl: string
      coinGameType: string
      wallets: UserWallet[]
      normalAuditFees:string
      promotionAuditFees:string
    } | null
    platAddress: string
    platsList: T[]
    channelList: T[]
    cryptoList: T[]
    depositList: DepositListType[]
    withdrawChannelList: T[]
    withdrawDetail: WithdrawDetail.WithdrawDetail | null
    coinList: Coin[]
    cryptoAddressList: CryptoAddress[]
  }

  namespace Socket {
    type MessageMap = {
      [SocketAction.PUSH_WN]: {
        count: `${number}`
      }
      [SocketAction.PUSH_DN]: {
        count: `${number}`
      }
      [SocketAction.NOTICE_CHANGE]: {
        title: string
        content: string
      }
      [SocketAction.COIN_CHANGE]: {
        uuid: string
        coin: number
      }
      [SocketAction.DEPOSIT_COIN_CHANGE]: {
        uuid: string
        coin: number
      }
    }
    type Data = {
      action: SocketAction
      divice: "D"
      // message?: MessageMap[Data["action"]]
    }
    type State = {
      data?: Data | null
      ws: WebSocket | null
      status: WebSocketStatus | "NONE"
    }
  }

  namespace Affiliate {
    type CommissionRate = {
      agentLevel: Number
      agentLevelRate: NumberString
    }
    type LevelDetails = {
      bettingTurnOver: NumberString
      commission: NumberString
      nums: number
    }
    type CommissionRecord = {
      agentLevel: 1
      bettingTurnOver: NumberString
      commissionUsd: NumberString
      createdAt: number
      currency: null
      updatedAt: number
      username: string
    }
    type __State = {
      commissionRateList: CommissionRate[]
      teamBenefits: {
        allowWithdrawal: NumberString
        todayIncreaseAgentNum: number
        totalAgentNum: number
        totalCommission: NumberString
      }
      subordinateDetails: {
        bettingTurnOver: NumberString
        commission: NumberString
      } & {
        [K in `level${1 | 2 | 3}`]: LevelDetails
      }
      commissionRecords: BaseListDataType<CommissionRecord>
    }

    type State = {
      [K in keyof __State]: __State[K] | null
    }
  }

  namespace Vip {
    type State = {
      userLevelInfo: {
        betPercentage: `${number}`
        betSum: NumberString
        birthdayReward: number
        code: string
        depositPercentage: NumberString
        depositSum: NumberString
        depositUpgradeMax: number
        depositUpgradeMin: number
        icon: string
        id: number
        monthReward: number
        name: string
        needBet: NumberString
        needDeposit: NumberString
        scoreUpgradeMax: number
        scoreUpgradeMin: number
        upgradeReward: number
        userLevelBasicInfoList: { id: number; name: string }[]
        weekReward: number
        withdrawalCoin: number
        withdrawalCount: number
      } | null
      userLeveReward:
        | {
            category: number
            coin: NumberString
            id: `${number}`
            receiveAt: number
            status: number
          }[]
        | null
      userLevelBenefits:
        | {
            birthdayReward: number
            depositUpgrade: number
            id: number
            monthReward: number
            name: string
            scoreRelegation: number
            scoreUpgrade: number
            upgradeReward: number
            weekReward: number
            withdrawalCoin: number
            withdrawalCount: number
          }[]
        | null
      userLevelDetails: {
        userLevelInfoList: {
          icon: string
          id: number
          name: string
          relegationDay: number
          scoreRelegation: number
          scoreUpgrade: number
        }[]
        userLevelRebateConfigList: {
          groupId: number
          userLevelRebateList: {
            id: number
            name: string
            rebateRate: NumberString
          }[]
        }[]
        userLevelWithdrawalInfo: {
          id: number
          name: string
          withdrawalCoin: number
          withdrawalCount: number
        }[]
      } | null
      memberShipDetails: {
        accumulatedDeposit: NumberString
        code: string
        name: string
        nextCode: string
        nextName: string
        score: number
        scoreUpgradeMax: number
        scoreUpgradeMin: number
      } | null
      memberShipLevelDetails:
        | {
            code: string
            icon: string
            id: number
            name: string
            points: number
            pointsMultiplier: number
            tierReward: number
          }[]
        | null
    }
  }
  namespace Blog {
    type Blog = {
      id: number
      lang: string
      category: string
      image: string
      title: string
      titleSub: string
      content: string
      recommend: number
      status: number
      sort: number
      createBy: string
      updateBy: string
      createdAt: number
      updatedAt: number
    }

    type __State = {
      blogList: BaseListDataType<Blog>
      currentBlog: Blog
    }
    type State = ToState<__State>
  }

  namespace CheckIn {
    type CheckInRuleDto = {
      days: number
      iconImg: string
      awardAmount: NumberString
      auditTimes: number
      isCheckedIn: boolean
    }

    type CurrentActivity = {
      isPopUp: number
      currentDay: number
      checkInStatus: number
      depositAmountLeft: NumberString

      checkInRuleDtoList: CheckInRuleDto[]

      checkInInfoDto: {
        langCode: string
        title: string
        img: string
        details: string
      }
    }
    type __State = {
      currentActivity: CurrentActivity
      isClosed: boolean
      isCheckIn: boolean
      show: boolean
      animationShow: boolean
      // 點擊按鈕、直接關閉來決定後續顯示
      story: "Click" | "Close"
    }

    type State = ToState<Omit<__State, "animationShow" | "show">> &
      Pick<__State, "animationShow" | "show">
  }

  namespace Persist {
    type __State = Pick<
      User.__State,
      "isLogin" | "info" | "showZeroBalance" | "showFiatBalance"
    >
    type State = ToState<__State>
  }

  namespace Svg {
    type State = {
      svgIdList: string[]
      svgViewBoxMap: Record<string, { width: string; height: string }>
      svgSpriteLoaded: Boolean
    }
  }
  namespace Asset {
    type State = {
      assetMap: Record<string, string>
    }
  }

  /** 活动 */
  namespace Activity {
    // 圣诞节活动助力讯息
    type ChristmasHelpDetail = {
      // 活動id
      activityId: string
      // 領過幾個禮盒
      applyTimes: number
      // 助力者助力成功的獎金
      bonus: number
      // 已完成百分比
      completePer: string
      // 1阶段的奖金
      firstStageBonus: string
      // 1阶段助力的百分比
      firstStagePer: string
      // 4阶段的奖金
      fourthStageBonus: string
      // 助力鏈接
      link: string
      // 助力點數
      point: number
      // 是否需分享截圖才能領取禮盒,0表示否，1表示是
      screenshot: 0 | 1
      // 2阶段的奖金
      secondStageBonus: string
      // 2阶段助力的百分比
      secondStagePer: string
      // 活動進度 -1:還未有活動的充值紀錄 0:有充值但充值還未成功 ,1:還未有助力紀錄(圖6) 2 返回助力者階段訊息 3: 助力審核期間IN REVIEW
      status: -1 | 0 | 1 | 2 | 3
      // 3阶段的奖金
      thirdStageBonus: string
      // 3阶段助力的百分比
      thirdStagePer: string
      // 活动倒计时
      countdown: number
      // 0新用戶 1老用戶
      newOldUser: number
      // 提示百分比
      reminderPer: number
      // 提示内容
      reminderContent: string
    }

    type ProcessItem = {
      per?: string
      bonus?: string
      status: GiftBoxStatus
    }
  }
}
