export const isIOS = () => {
  const ua = navigator.userAgent
  return (
    /iPad|iPhone|iPod/.test(ua) ||
    (ua.includes("Mac") && "ontouchend" in document)
  )
}

export const isAndroid = () => {
  const ua = navigator.userAgent
  return /Android/.test(ua)
}
